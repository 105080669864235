import Modal from 'react-modal';
import { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { AuthContext } from 'context/AuthContext';
import BB_ROUTES from 'routes/const';
import { toast } from 'react-toastify';
import { TiTimes } from 'react-icons/ti';

export function VerifyAcceptTerms({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();

  const modalID = 'accept-terms';

  const { user } = useContext(AuthContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [closeAccept, setCloseAccept] = useState(false);

  useEffect(() => {
    if (closeAccept && location.pathname === '/regulamento') {
      return;
    }
    if (!!user && !user.extra.accept_terms) {
      if (props.wait) {
        setTimeout(() => {
          setIsOpen(true);
        }, props.wait);
      } else {
        setIsOpen(true);
      }

      if (location.pathname !== '/regulamento') {
        navigate(BB_ROUTES.RULE());
      }
    }
  }, [user, location.pathname]);

  function handleCloseModal() {
    setIsOpen(false);

    if (location.pathname === '/regulamento') {
      setCloseAccept(true);
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => handleCloseModal()}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        portalClassName="relative z-[600]"
        overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen h-screen p-6 overflow-y-auto custom-notification-modal"
        contentElement={() => (
          <div className="modalDetailBox bg-white shadow rounded-[10px] py-5 px-5 dialog transition-all flex flex-col justify-between w-full md:w-[450px] ">
            <div className="flex justify-end items-end mb-3">
              {/* <button onClick={handleCloseModal} className="rounded-full ">
                <TiTimes color="#69696E" size={25} />
              </button> */}
            </div>
            <div
              className="modalBody  max-h-[480px] h-fit overflow-y-auto  relative mt-5 mb-2 text-center item"
              id="mix"
            >
              <h2 className="">Olá, Gammer!</h2>
              <div className="">
                <div>
                  <p>
                    Você ainda não aceitou os termos do Regulamento. Esse
                    procedimento é importante para que você continue no Game Tô
                    On, receba suas Estrelas, Moedas e depois faça o resgate na
                    Loja de Recompensas. Clique em OK, faça a leitura e clique
                    em "Aceitar Termos".
                  </p>
                </div>
                <button
                  className="bg-[#11beff] text-white py-2 px-5 rounded"
                  onClick={() => handleCloseModal()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
