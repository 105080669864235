import { AuthContext } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import React, { useEffect, useState, useContext } from "react";
import { TiTimes } from "react-icons/ti";
import Modal from "react-modal";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { getOriginalIdea, saveOrUpdateIdea } from "services/ideia.service";

import "./styles.scss";

import "react-accessible-accordion/dist/fancy-example.css";

const ModalDestaque = () => {
	const { user, refreshUser } = useContext(AuthContext);
	const { openModal, modalData, closeModal } = useModal();

	const modalID = "modal-destaque-cutom";

	const [loading, setLoading] = useState(true);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [originalIdea, setOriginalIdea] = useState<any>();

	ReactModal.setAppElement("#modal");

	async function handleCloseModal() {
		setIsOpen(false);
	}

	useEffect(() => {
		if (!user) return;
		loadData();
	}, [user, openModal, modalData]);

	function loadData() {
		if (!modalData) return;
		getOriginalIdea(modalData.config._id).then((resp) => {
			if (resp.player) {
				setOriginalIdea(resp);
				setIsOpen(openModal === modalID);
			} else {
				toast.error("Erro ao buscar ideia.");
				setIsOpen(false);
			}
		});
	}

	function destacarIdeia(ideia: any, isDestaque: boolean) {
		if (isDestaque === true) {
			ideia.isDestaque = true;
			modalData.config.isDestaque = true;
		} else if (isDestaque === false) {
			ideia.isDestaque = false;
			modalData.config.isDestaque = false;
		}
		saveOrUpdateIdea(ideia)
			.then((resp) => {
				if (resp) {
					toast.success("Ideia salva");
				} else {
					toast.error("Erro ao salvar a ideia.");
				}
			})
			.finally(() => {
				setIsOpen(false);
			});
	}

	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => handleCloseModal()}
				contentLabel="Example Modal"
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={false}
				portalClassName="relative z-[600]"
				overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen h-screen p-6 overflow-y-auto custom-notification-modal"
				contentElement={() => (
					<div className="modalDetailBox bg-white shadow rounded-[10px] py-5 px-5 dialog transition-all flex flex-col justify-between w-full md:w-[450px] ">
						<div className="flex justify-end items-end mb-3">
							<button onClick={handleCloseModal} className="rounded-full ">
								<TiTimes color="#69696E" size={25} />
							</button>
						</div>
						<div
							className="modalBody  max-h-[480px] h-fit overflow-y-auto  relative mt-5 mb-2 text-center item"
							id="mix"
						>
							<h2 className="">
								{originalIdea.isDestaque
									? "REMOVER DESTAQUE"
									: "ADICIONAR DESTAQUE"}
							</h2>
							<div className="">
								<p>
									{originalIdea.isDestaque ? (
										<>
											<p>
												Remover o destaque da ideia <b>{originalIdea.title}</b>
											</p>
											<button
												onClick={() => {
													destacarIdeia(originalIdea, false);
												}}
												className="bg-[#f50226] rounded px-[20px] py-[10px] text-white"
											>
												Remover
											</button>
										</>
									) : (
										<>
											<p>
												Adicionar destaque a ideia <b>{originalIdea.title}</b>
											</p>
											<button
												onClick={() => {
													destacarIdeia(originalIdea, true);
												}}
												className="bg-[#735cc6] rounded px-[20px] py-[10px] text-white"
											>
												Destacar
											</button>
										</>
									)}
								</p>
							</div>
						</div>
					</div>
				)}
			/>
		</>
	);
};

export default ModalDestaque;
