import ModuleInfo from "classes/module.interface";
import { ProgressInfo } from "classes/progress.interface";
import { Settings } from "classes/settings.interface";
import ProgressBar from "components/ProgressBar";
import Skeleton from "components/Skeleton";
import UserProgress from "components/UserProgress";
import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import BB_ROUTES from "routes/const";
import {
	getContentModule,
	getProgress,
	getServerTime,
	getSingleTrail,
} from "services/elarning.service";

const Module = () => {
	const navigate = useNavigate();

	const { setBreadCrumbItens, setPageTitle, team, user, contrast } = useAuth();
	const params = useParams<{ id: string }>();

	const [globalSettings, setGlobalSettings] = useState<Settings>();

	const [loading, setLoading] = useState(true);
	const [module, setModule] = useState<Array<ModuleInfo>>([]);
	const [progress, setProgress] = useState<ProgressInfo>();
	const [expectedPercent, setExpectedPercent] = useState(0);
	const [allowStart, setAllowStart] = useState(false);
	const [dateStart, setDateToStart] = useState("");

	useEffect(() => {
		async function loadGlobalSettings() {
			const settings = await getSingleTrail(String(params.id));
			const currentDate = await getServerTime();

			try {
				let datasettings = {
					title: settings[0].title,
					color: settings[0].extra.color,
				};

				if (!!user?.extra && !!settings[0].extra.days_to_start) {
					const admission = new Date(`${user?.extra.data_posse} 23:59:59`);
					const startDate = admission.setDate(
						admission.getDate() + settings[0].extra.days_to_start,
					);

					const d = new Date(startDate);

					setDateToStart(new Intl.DateTimeFormat("pt-BR").format(d));
					if (currentDate >= startDate) {
						setAllowStart(true);
					}
					datasettings = {
						...datasettings,
						...{
							start_period: startDate,
							end_period: d.setDate(
								d.getDate() + settings[0].extra.days_to_complete,
							),
							max_coin: settings[0].extra.max_coin || 0,
							max_star: settings[0].extra.max_star || 0,
						},
					};
				}
				setPageTitle(datasettings?.title);
				setGlobalSettings(datasettings);
			} catch (e) {
				console.log(e);
			}
		}

		async function loadData() {
			const data = await getContentModule(params.id);
			const progress = await getProgress(String(user?._id), String(params.id));

			if (data.length === 0) {
				setLoading(false);
				return;
			}

			console.log(data);

			try {
				const itens: Array<ModuleInfo> = [];

				data.forEach((item) => {
					const currentProgress = progress?.items.filter((f) => {
						return f._id == item._id;
					});

					const newItem = {
						_id: item._id,
						type: item.type,
						title: item.title,
						extra: item.extra,
						isFinish:
							!!currentProgress && currentProgress[0] && currentProgress[0].done
								? true
								: false,
						percent: currentProgress ? currentProgress[0].percent : 0,
					} as ModuleInfo;

					itens.push(newItem);
				});

				setProgress(progress);
				setModule(itens);
			} finally {
				setLoading(false);
			}
		}

		loadGlobalSettings();
		loadData();
	}, [params, user]);

	useEffect(() => {
		setPageTitle(globalSettings?.title);

		setBreadCrumbItens([
			{
				isFirst: true,
				name: "Jornada/",
				active: false,
				path: "/trilha",
			},
			{
				isFirst: false,
				name: String(globalSettings?.title),
				active: true,
				path: "/trilha",
			},
		]);
	}, [globalSettings]);

	function handler(item: ModuleInfo, index: number) {
		const currentTime = new Date();
		const nextUrl = BB_ROUTES.BOARD(`${item._id}-${params.id}`);

		if (item.extra.block_before_start && user?.extra) {
			const admission = new Date(`${user?.extra.data_posse}T00:00:00`);
			const startDate = admission.setDate(
				admission.getDate() + item.extra.days_to_start,
			);
			const d = new Date(startDate);
			setDateToStart(new Intl.DateTimeFormat("pt-BR").format(d));

			if (currentTime.getTime() >= d.getTime()) {
				navigate(nextUrl);
			} else {
				toast.warning(
					`Este módulo será liberado em ${new Intl.DateTimeFormat(
						"pt-BR",
					).format(d)}`,
				);
			}
		} else {
			navigate(nextUrl);
		}
	}

	return (
		<>
			{!loading && module.length === 0 ? (
				<>
					<div className="text-center items-center flex flex-col mt-14 h-full">
						<h3 className="text-center font-bold text-3xl text-white">Ops!</h3>
						<p className="text-base">
							Esta jornada não esta disponível para você!
						</p>
						<button
							className="btn-team text-white text-base rounded-xl px-3 py-2 mt-3 hover:opacity-80 translate-x-3"
							onClick={(e) => navigate(BB_ROUTES.TRAIL())}
						>
							Ir para Jornadas
						</button>
					</div>
				</>
			) : (
				<>
					<div className="module w-full lg:mt-28 mt-24">
						<div
							className={expectedPercent ? "mb-11 lg:mb-32" : "mb-11 lg:mb-11"}
						>
							<UserProgress
								startDate={globalSettings?.start_period}
								endDate={globalSettings?.end_period}
								barColor={
									contrast
										? "#fcfc30"
										: globalSettings?.color.includes("#")
											? globalSettings?.color
											: `#${globalSettings?.color}`
								}
								currentProgress={progress?.percent || 0}
								expectedProgress={0}
								expectedDays={0}
							/>
						</div>

						{!!module && module.length > 0 && !loading && (
							<>
								<div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
									{module.map((item: ModuleInfo, index: number) => (
										<article
											key={`${item._id}_${index}`}
											onClick={() => handler(item, index)}
											className={`rounded-[10px] py-[30px] px-[22px] mb-5 ${
												contrast
													? "bg-black border-yellow border-2"
													: Number(item.percent) >= 100
														? `bg-pink-100`
														: `bg-white`
											} cursor-pointer relative transition hover:opacity-80`}
											aria-label={item.title}
											role="link"
											tabIndex={0}
										>
											<h4
												className={`${
													contrast ? "text-yellow" : "text-gray-300"
												} mb-3 text-xl font-normal`}
												aria-hidden={true}
												tabIndex={-1}
											>
												{item.title}
											</h4>
											<div
												tabIndex={item.extra.description ? 0 : -1}
												aria-hidden={item.extra.description ? false : true}
												className={`${
													contrast ? "text-yellow" : "text-gray-300"
												} text-sm font-light mb-12`}
												dangerouslySetInnerHTML={{
													__html: item.extra.description,
												}}
											></div>
											<ProgressBar
												color={
													contrast
														? "#fcfc30"
														: globalSettings?.color.includes("#")
															? globalSettings?.color
															: `#${globalSettings?.color}`
												}
												percent={item?.percent || 0}
											/>
										</article>
									))}
								</div>
							</>
						)}

						{loading && !module && (
							<>
								<div className="grid gap-7 md:grid-cols-2 lg:grid-cols-3">
									<Skeleton size={5} type="MODULE" />
								</div>
							</>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default Module;
