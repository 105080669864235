import { AuthContext, useAuth } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";

import Skeleton from "components/Skeleton";
import { UserAvatar } from "components/user-avatar";
import { AiFillStar } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import BB_ROUTES from "routes/const";
import { RankingConhecimentoCompleto } from "services/ideia.service";
import { getNameSetor } from "utils/constants";
import "./style.scss";

const RankingConhecimentoPage = () => {
	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(false);

	const [loading, setLoading] = useState(false);
	const [ranking, setRanking] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const { setBreadCrumbItens, setPageTitle } = useContext(AuthContext);
	const params = useParams();

	const { user, contrast } = useContext(AuthContext);
	const [filtro, setFiltro] = useState("");

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		handleResize();
	}, []);

	const handleResize = () => {
		if (window.innerWidth <= 960) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	const handleFiltroChange = (event: any) => {
		setFiltro(event.target.value);
	};

	useEffect(() => {
		const data = ranking.filter((item: any) =>
			item.name.toLowerCase().includes(filtro.toLowerCase()),
		);
		setFilteredData(data);
	}, [filtro]);

	useEffect(() => {
		setBreadCrumbItens([
			{
				isFirst: true,
				name: "Jornada / ",
				active: false,
				path: BB_ROUTES.HOME(),
			},
			{
				isFirst: false,
				name: " Ranking do conhecimento",
				active: true,
				path: "/ranking-conhecimento",
			},
		]);
		setPageTitle("Ranking do conhecimento");
	}, [setBreadCrumbItens]);

	useEffect(() => {
		if (!ranking.length) {
			getData();
		}
	}, [user?.teams]);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		scrollToTop();
	}, [params]);

	function getData() {
		setLoading(true);
		RankingConhecimentoCompleto(user?.teams)
			.then((resp) => {
				setRanking(resp);
				setFilteredData(resp);
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
			});
	}

	const handler = (item: any) => {
		if (item.player == user?._id) {
			navigate(BB_ROUTES.RANKINGCONHECIMENTO());
		}
		// e.player === user?._id;
	};

	const ariaLabelBuilder = (pageNumber: number, isCurrent: number) => {
		if (isCurrent) {
			return `Página ${pageNumber}, atual`;
		} else {
			return `ir para a página ${pageNumber}`;
		}
	};

	function Items({ currentItems }: any) {
		return (
			<>
				{currentItems && currentItems.length ? (
					<div>
						{currentItems.map((e: any, index: any) => (
							<article
								className="flex item-position items-center mb-7"
								key={e._id}
								role="contentinfo"
								tabIndex={0}
								aria-label={`Em ${e.position}º LUGAR o ${getNameSetor(
									e.p?.teams,
								)} ${e.name}, com ${e.total} estrela${e.total > 1 ? "s" : ""}`}
							>
								<div
									className="thumb mr-3 w-[80px] h-[80px]"
									aria-hidden={true}
								>
									{e.p.extra.avatar ? (
										<UserAvatar
											data={{ ...e.p, ...e.p.extra }}
											responsive={true}
										/>
									) : (
										<span>
											{e.p.image ? (
												<img src={e.p?.image?.small?.url} alt="Imagem user" />
											) : (
												<img
													src={require("../../assets/img/user.png")}
													alt="Imagem user"
												/>
											)}
										</span>
									)}
								</div>
								<div
									className={`${
										e.player === user?._id ? "my-position" : ""
									} w-full`}
								>
									{e.player === user?._id && (
										<div className="bg-yellow rounded-bl-lg rounded-br-lg py-2 px-2 text-center w-28 mb-3">
											<span className="text-gray-300 text-xs">
												Sua posição!
											</span>
										</div>
									)}
									<p
										className={`${
											contrast ? "text-yellow" : "text-white"
										} uppercase text-md font-bold mb-1`}
									>
										{e.position}º LUGAR
									</p>
									<h5
										className={`text-md ${
											contrast ? "text-yellow" : `text-white`
										}  font-medium`}
									>
										{e.name}
									</h5>
									<p
										className={`text-[12px] ${
											contrast ? "text-yellow" : "text-white"
										} font-normal`}
									>
										{getNameSetor(e.p?.teams)}
									</p>
									<div className="flex">
										<button
											className={`flex ${
												contrast ? "text-yellow" : "text-white"
											} mr-4 text-[12px] items-center ${
												e.player === user?._id
													? "cursor-pointer"
													: "cursor-text"
											}`}
											role="contentinfo"
											aria-hidden={true}
											tabIndex={-1}
											onClick={(e) => handler(e)}
										>
											<AiFillStar color="#FCFC30" size={20} />{" "}
											<span
												className={e.player === user?._id ? "underline" : ""}
											>
												{e.total} estrela{e.total > 1 ? "s" : ""}
											</span>
										</button>

										{/* <button className="flex font-light text-[10px] items-center">
                      <AiFillStar color="#FCFC30" size={18} />{' '}
                      <span className="underline">30 moedas</span>
                    </button> */}
									</div>
								</div>
							</article>
						))}
					</div>
				) : (
					<p className="text-4xl text-center"></p>
				)}
			</>
		);
	}

	function PaginatedItems({ itemsPerPage }: any) {
		// Here we use item offsets; we could also use page offsets
		// following the API or data you're working with.
		const [itemOffset, setItemOffset] = useState(0);
		const { contrast } = useAuth();

		// Simulate fetching items from another resources.
		// (This could be items from props; or items loaded in a local state
		// from an API endpoint with useEffect and useState)
		const endOffset = itemOffset + itemsPerPage;
		console.log(`Loading items from ${itemOffset} to ${endOffset}`);
		const currentItems = filteredData.slice(itemOffset, endOffset);
		const pageCount = Math.ceil(filteredData.length / itemsPerPage);

		// Invoke when user click to request another page.
		const handlePageClick = (event: any) => {
			const newOffset = (event.selected * itemsPerPage) % filteredData.length;
			console.log(
				`User requested page number ${event.selected}, which is offset ${newOffset}`,
			);
			setItemOffset(newOffset);
		};

		return (
			<>
				<Items currentItems={currentItems} />
				<ReactPaginate
					breakClassName={`${
						contrast ? "text-yellow" : "text-gray-300"
					} text-base`}
					previousClassName={`${
						contrast ? "text-yellow" : "text-gray-300"
					} text-base`}
					nextClassName={`${
						contrast ? "text-yellow" : "text-gray-300"
					} text-base`}
					breakLabel="..."
					nextLabel={isMobile ? "Próxima" : "Próxima >"}
					onPageChange={handlePageClick}
					pageRangeDisplayed={2}
					pageCount={pageCount}
					ariaLabelBuilder={ariaLabelBuilder}
					previousLabel={isMobile ? "Anterior" : "< Anterior"}
					renderOnZeroPageCount={null}
					pageLinkClassName={`${
						contrast ? "text-yellow" : "text-gray-300"
					} text-base underline px-3`}
					activeLinkClassName={`${
						contrast ? "text-white" : "text-purple"
					} no-underline`}
					className={`pagination mt-6 ${
						currentItems.length <= 1 ? "hidden" : ""
					} ${contrast ? "has-contrast" : ""}`}
				/>
			</>
		);
	}

	return (
		<div className="main-ideia " id="pageRankingConhecimento">
			<section className="my-7 px-[14px] py-7 lg:px-[70px] lg:mb-[18px] relative transition rounded-[10px] border border-white">
				<p className={`text-base ${contrast ? "text-yellow" : "text-white"}`}>
					<strong>Ranking do conhecimento</strong>
				</p>
				<section className="actions relative mb-[30px] flex justify-center mt-5">
					<input
						type="text"
						placeholder="Buscar..."
						className={`w-full rounded-[5px] bg-transparent border p-2 ${
							contrast
								? "bg-black border-yellow text-yellow"
								: "border-white  text-white"
						} `}
						onChange={handleFiltroChange}
					/>
				</section>
				<article className="my-10">
					{filteredData && filteredData.length > 0 && (
						<PaginatedItems itemsPerPage={isMobile ? 40 : 10} />
					)}
					{loading && <Skeleton size={10} type="LIST" />}
				</article>
			</section>
		</div>
	);
};

export default RankingConhecimentoPage;
